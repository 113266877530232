.characteristic {
  margin: 0 auto 20px;
  padding: 20px;
  border: 1px solid #9e9e9e;
  border-radius: 10px;


  &-without-border {
    border: none;
    margin-bottom: 0;
  }

  &__title {
    display: flex;
    & span {
      margin-right: 10px;
    }
  }

  &__buttons {
    display: inline;
    text-align: center;
    margin-right: 0;
    margin-left: auto;
  }

  &__button {
    background-color: inherit;
    border: 0;
    &:hover, &:focus {
      background-color: inherit;
    }
    &:hover {
      transform: scale(1.5, 1.5);
    }
  }

  @media (max-width: 576px) {
    h4 {
      font-size: 24px;
    }
    h5 {
      font-size: 20px;
    }
    h6 {
      font-size: 16px;
    }
  }
}

.info {
    background-color: #f7f7f7;
    margin-bottom: 20px;
    padding: 0 20px;
    

    &__wrapper {
        max-width: 1260px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 31px;
        max-width: 583px;
    }

    &__list {
        list-style-type: disc !important;
    }

    &__description {
        font-size: 16px;
        max-width: 550px;
        padding-bottom: 10px;
    }

    &__picture {
        & img {
            width: 100%;
        }
    }

    &__button {
        margin-top: 20px;
        border: 1px solid orange;
    }
    @media (max-width: 850px) {
        &__wrapper {
            flex-direction: column;
            align-items: center;
        }

        &__description {
            max-width: 583px;
        }
    }
}

.header {
  background-color: #ffffff;
  color: #000;
  padding: 10px;

  .container {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1260px;
    margin: 0 auto;
  }
}

.col {
  display: flex;
  gap: 10px;
}

.nav {
  display: flex;
  gap: 20px;
  background-color: inherit;
  box-shadow: none;
  color: inherit;
}

.logo {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  width: 110px;
  & img {
    width: 100%;
  }
}

.sendMail {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  margin: 0 10px;

  &__icon {
    margin-right: 10px;
  }

  &__email {
    font-size: 20px;
    line-height: 25px;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 800px) {
  .header {
    .container {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media (max-width: 580px) {
    .col {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 350px) {
    .sendMail {
      &__email {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}

.schedule {
    &__title {
        text-align: center;
        font-size: 18px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    &__timepicker {
        &::before {
            display: none;
        }
        &.MuiInput-underline.Mui-focused::after {
            display: none;
        }
        width: 100%;
    }

    &__day {
        padding: 5px;
        flex: 1 0 200px;
        & p {
            margin-bottom: 20px;
        }
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        & span {
            font-size: 12px;
            margin-right: 5px;
        }
    }
}

@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,700&display=swap);
*{box-sizing:border-box;margin:0;padding:0}body{font-family:"Roboto",sans-serif;position:relative}.container{margin:0}

.authPage{padding-top:50px;max-width:600px;margin:0 auto;text-align:center}.authPage .authButton{margin-right:20px}
.Header_header__1v0yI{background-color:#fff;color:#000;padding:10px}.Header_header__1v0yI .Header_container__21wXn{min-height:80px;display:flex;justify-content:space-between;align-items:center;max-width:1260px;margin:0 auto}.Header_col__1rOJp{display:flex;grid-gap:10px;gap:10px}.Header_nav__LNsaU{display:flex;grid-gap:20px;gap:20px;background-color:inherit;box-shadow:none;color:inherit}.Header_logo__3T0lc{font-size:20px;font-weight:bold;margin-right:10px;width:110px}.Header_logo__3T0lc img{width:100%}.Header_sendMail__3B9LG{display:flex;align-items:center;font-size:12px;line-height:12px;margin:0 10px}.Header_sendMail__icon__3s76g{margin-right:10px}.Header_sendMail__email__2doJ8{font-size:20px;line-height:25px}.Header_link__1uLZ-{color:inherit;text-decoration:none}@media(max-width: 800px){.Header_header__1v0yI .Header_container__21wXn{flex-wrap:wrap;justify-content:center}}@media(max-width: 800px)and (max-width: 580px){.Header_col__1rOJp{margin-bottom:20px}}@media(max-width: 800px)and (max-width: 350px){.Header_sendMail__email__2doJ8{font-size:20px;line-height:25px}}
.footer{font-size:16px;background-color:#f7f7f7;padding:40px 0 20px}.footer__container{margin:0 auto !important}@media(max-width: 600px){.footer__col1{padding-bottom:20px !important}.footer__col2{border-left:none}}@media(min-width: 601px){.footer__col2{padding-left:20px !important}}
.documents{display:flex;flex-wrap:wrap;max-width:1260px;grid-gap:20px;gap:20px}.documents__title{font-size:28px;line-height:33px;margin-bottom:31px;max-width:583px}.documents__wrapper{min-height:40vh;margin-bottom:40px}.documents__document{flex:0 1 400px;min-width:300px;border:1px solid #c4c4c4;padding:10px 10px 60px;position:relative}.documents__name{font-size:18px;font-weight:bold}.documents__date{margin-bottom:20px}.documents__buttons{right:20px;bottom:20px;position:absolute;display:flex;justify-content:flex-end;grid-gap:10px;gap:10px}
.info{background-color:#f7f7f7;margin-bottom:20px;padding:0 20px}.info__wrapper{max-width:1260px;margin:0 auto;display:flex;justify-content:space-between;align-items:center}.info__title{font-size:28px;line-height:33px;margin-bottom:31px;max-width:583px}.info__list{list-style-type:disc !important}.info__description{font-size:16px;max-width:550px;padding-bottom:10px}.info__picture img{width:100%}.info__button{margin-top:20px;border:1px solid orange}@media(max-width: 850px){.info__wrapper{flex-direction:column;align-items:center}.info__description{max-width:583px}}
.task{margin-bottom:10px;padding-right:30px !important;position:relative}.task__title{display:flex;padding:0 22px}.task__help{position:absolute;right:10px;top:3px}.task__helper-text{margin-left:10px;font-size:12px}.task__fieldset{border:none}.task__bad-result{margin-bottom:20px}.task__bad-result [type=radio]:checked+span:after,.task__bad-result [type=radio].with-gap:checked+span:after{background-color:#f44336}.task__bad-result [type=radio]:checked+span:after,.task__bad-result [type=radio].with-gap:checked+span:before,.task__bad-result [type=radio].with-gap:checked+span:after{border-color:#f44336}.task__bad-result,.task__good-result{padding:0 8px}.my-modal-wrapper{position:absolute;left:0;right:0;bottom:0;top:0;display:flex;justify-content:center;align-items:center;margin:0 5px}.my-modal-content{max-width:800px;background-color:#fff;padding:20px;overflow-y:auto;max-height:80vh}
.set{padding-bottom:10px}.set__buttons{text-align:left}.set__title{text-align:left;padding:0 22px}.set__button{margin:0 20px 10px}.set__paragraph{padding:0 20px;margin-bottom:10px;margin-left:17px}.set__spoilers{margin-bottom:20px}
.characteristic{margin:0 auto 20px;padding:20px;border:1px solid #9e9e9e;border-radius:10px}.characteristic-without-border{border:none;margin-bottom:0}.characteristic__title{display:flex}.characteristic__title span{margin-right:10px}.characteristic__buttons{display:inline;text-align:center;margin-right:0;margin-left:auto}.characteristic__button{background-color:inherit;border:0}.characteristic__button:hover,.characteristic__button:focus{background-color:inherit}.characteristic__button:hover{-webkit-transform:scale(1.5, 1.5);transform:scale(1.5, 1.5)}@media(max-width: 576px){.characteristic h4{font-size:24px}.characteristic h5{font-size:20px}.characteristic h6{font-size:16px}}
.input-field{min-height:60px !important}.input-field input{min-height:60px !important}.MuiChip-root{height:auto !important;min-height:32px}.MuiChip-label{white-space:normal !important;height:auto !important;display:inline}.datepicker-container .datepicker-cancel,.datepicker-container .datepicker-table td.is-today,.datepicker-container .datepicker-done{color:#ff9800}.datepicker-container .datepicker-date-display{background-color:#ff9800}.dropdown-content li>a,.dropdown-content li>span{color:#ff9800}.MuiMenuItem-root{white-space:normal !important;align-items:start !important}.datepicker-modal.open~.datepicker{font-size:0}.MuiFormLabel-root.Mui-focused{color:#26a69a !important}.MuiInput-underline:hover:not(.Mui-disabled):before{color:#26a69a !important}.select{min-height:46px !important}.select::after{border-color:#26a69a !important}.select-field{margin-bottom:20px}.select-field .helper-text{display:block;text-align:center;margin:0 auto}.checkbox-wrapper{display:flex;align-items:flex-start}.ipra-span{line-height:25px;margin-right:10px}.cursor-pointer{cursor:pointer}h2{font-size:32px}h3{font-size:28px}h4{font-size:24px}h5{font-size:20px}.save-load{position:fixed;bottom:0;right:0;display:flex;background-color:#fff;border-radius:5px}.save-load__icon{max-width:50px}.save-load__icon img{width:100%}
.schedule__title{text-align:center;font-size:18px}.schedule__wrapper{display:flex;justify-content:space-between;flex-wrap:wrap;margin-bottom:30px}.schedule__timepicker{width:100%}.schedule__timepicker::before{display:none}.schedule__timepicker.MuiInput-underline.Mui-focused::after{display:none}.schedule__day{padding:5px;flex:1 0 200px}.schedule__day p{margin-bottom:20px}.schedule__input-wrapper{display:flex;flex-direction:column;align-items:center}.schedule__input-wrapper span{font-size:12px;margin-right:5px}
.add-option{display:flex;justify-content:right;align-items:flex-start;grid-gap:20px;gap:20px;margin-top:20px}.add-option__input{flex:1 0 auto}.add-option .add-option__buttons{font-size:12px;display:flex;align-items:center}.add-option .add-option__button{margin-left:10px}.add-option .add-option__button:hover{-webkit-transform:scale(1.5);transform:scale(1.5);transition-property:-webkit-transform;transition-property:transform;transition-property:transform, -webkit-transform;transition-duration:.5s}@media(max-width: 650px){.add-option{display:block}}

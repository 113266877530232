.documents {
    display: flex;
    flex-wrap: wrap;
    max-width: 1260px;
    gap: 20px;

    &__title {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 31px;
        max-width: 583px;
    }
    &__wrapper {
        min-height: 40vh;
        margin-bottom: 40px;
    }
    &__document {
        flex: 0 1 400px;
        min-width: 300px;
        border: 1px solid #c4c4c4;
        padding: 10px 10px 60px;
        position: relative;
    }

    &__name {
        font-size: 18px;
        font-weight: bold;
    }

    &__date {
        margin-bottom: 20px;
    }

    &__buttons {
        right: 20px;
        bottom: 20px;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}

.add-option {
    display: flex;
    justify-content: right;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;

    &__input {
        flex: 1 0 auto;
    }

    .add-option__buttons {
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .add-option__button {
        margin-left: 10px;
        &:hover {
            transform: scale(1.5);
            transition-property: transform;
            transition-duration: 0.5s;
        }
    }
    @media (max-width: 650px) {
        display: block;
    }
}

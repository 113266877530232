.task {
  margin-bottom: 10px;
  padding-right: 30px !important;
  position: relative;

  &__title {
    display: flex;
    padding: 0 22px;
  }

  &__help {
    position: absolute;
    right: 10px;
    top: 3px; 
  }

  &__helper-text{
    margin-left: 10px;
    font-size: 12px;
  }

  &__fieldset{
  border: none;
  }
  &__bad-result {

    margin-bottom: 20px;
    & [type="radio"]:checked + span:after,
    [type="radio"].with-gap:checked + span:after {
      background-color: #f44336;
    }
    & [type="radio"]:checked + span:after,
    [type="radio"].with-gap:checked + span:before,
    [type="radio"].with-gap:checked + span:after {
      border-color: #f44336;
    }
  }

  &__bad-result, &__good-result {
    padding: 0 8px;
  }


}

.my-modal-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.my-modal-content{
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
  max-height: 80vh;
}




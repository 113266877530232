.set {
  padding-bottom: 10px;
  &__buttons {
    text-align: left;
  }
  &__title {
    text-align: left;
    padding: 0 22px;
  }

  &__button {
    margin: 0 20px 10px;
  }
  &__paragraph {
    padding: 0 20px;
    margin-bottom: 10px;
    margin-left: 17px;
  }
  &__spoilers {
    margin-bottom: 20px;
  }
}

.footer {
    font-size: 16px;
    background-color: rgb(247, 247, 247);
    padding: 40px 0 20px;

    &__container {
        margin: 0 auto !important;
    }

    @media (max-width: 600px) {
        &__col1 {
            padding-bottom: 20px !important;
        }
        &__col2 {
            border-left: none;
        }
    }

    @media (min-width: 601px) {
        &__col2 {
            padding-left: 20px !important;
        }
    }
}

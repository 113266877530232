.input-field {
    min-height: 60px !important;
    & input {
        min-height: 60px !important;
    }
}
.MuiChip-root{
    height: auto !important;
    min-height: 32px;
}
.MuiChip-label{
    white-space: normal !important;
    height: auto !important;
    display: inline;
}

.datepicker-container {
    & .datepicker-cancel,
    & .datepicker-table td.is-today,
    & .datepicker-done {
        color: #ff9800;
    }

    & .datepicker-date-display {
        background-color: #ff9800;
    }
}

.dropdown-content li > a,
.dropdown-content li > span {
    color: #ff9800;
}

.MuiMenuItem-root {
    white-space: normal !important;
    align-items: start !important;
}

.datepicker-modal.open ~ .datepicker {
    font-size: 0;
}

.MuiFormLabel-root.Mui-focused {
    color: #26a69a !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    color: #26a69a !important;
}
.select {
    min-height: 46px !important;
    &::after {
        border-color: #26a69a !important;
    }
}
.select-field {
    margin-bottom: 20px;
    & .helper-text {
        display: block;
        text-align: center;
        margin: 0 auto;
    }
}

.checkbox-wrapper {
    display: flex;
    align-items: flex-start;
}
.ipra-span {
    line-height: 25px;
    margin-right: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

.save-load{
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: #fff;
    border-radius: 5px  ;
    &__icon {
        & img {
            width: 100%;
        }
        max-width: 50px;
    }
}